import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Menu, NavService } from '../../core/services/nav/nav.service';
import { ApiReturn } from '../../shared/models/global.model';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Input } from '@angular/core';
import { DataService } from '~/app/core/data.service';
import { Cache} from '../../core/lib/cache';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() isCollapsed: boolean;

  menus = [];
  userFullname = Cache.getCache("UserName");
  username: string = Cache.getCache("UserName");
  avatar: string = "assets/uploads/avatar-default.png";

  constructor(
    private router: Router, 
    public navServices: NavService,
    public toast: Notification,
    public translate: TranslateService,
    private dataService: DataService,
    public routerService: Router, 
    private route: ActivatedRoute,
    private modal: NzModalService,
    ){
  }

  ngOnInit() {
    this.getData();
    this.dataService.currentMessage.subscribe(message => this.onReceiveCall(message));

    this.loadHoTen();
  }

  loadHoTen()
  {
    let hoTen = Cache.getCache("HoTen");
    if(hoTen)
      this.userFullname = hoTen;
  }

  onReceiveCall(data: any) {
    if(data.type == "changeProfile")
    {
      this.loadHoTen();
    }
    if(data.type == "successChangeCustomer")
    {
      this.getData();
    }
  }

  getData()
  {
    this.menus = [];
    let current = window.location.pathname.substring(1);
    if(current == "auth/login")
      current = "home";
    this.navServices.getMenu()
    .subscribe((res: any) => {
      if(res.Code == 1)
      {
        this.menus = [];
        res.Data.forEach(item1 => {
          let it1 = {
            level: 1,
            title: item1.Ten,
            icon: item1.Icon,
            open: false,
            selected: item1.URL == current ? true : false,
            disabled: false,
            url: item1.URL,
            children: null,
            id: item1.ID,
            code: item1.Ma,
            reload: item1.Reload
          };
          if(item1.Children != null && item1.Children.length > 0)
          {
            it1.children = [];
            item1.Children.forEach(item2 => {
              let it2 = {
                level: 2,
                title: item2.Ten,
                icon: item2.Icon,
                open: false,
                selected: item2.URL == current ? true : false,
                disabled: false,
                url: item2.URL,
                children: null,
                id: item2.ID,
                code: item2.Ma,
                reload: item2.Reload,
              };
              if(it2.selected == true)
              {
                it1.open = true;
                it1.selected = true;
              }

              it1.children.push(it2);
            });
          }
          this.menus.push(it1);
        });
      }
    }, error => {
      console.log(error)
    });
  }

  menuClick(item)
  {
    console.log(item);
    this.menus.forEach(menu => {
      menu.open = false;
      if(menu.children != null && menu.children.length > 0)
      {
        menu.children.forEach(element => {
          element.selected = false;
          if(item.id == element.id){
            item.selected = true;
            item.open = true;
          }
        });
      }
    });
    if(item.url != null && item.url != '')
    {
      //this.router.navigate([item.url], {});
      if(item.reload)
        location.href = item.url;
      else
        this.router.navigate([item.url], {});
    }
  }
  
  info()
  {
    this.routerService.navigate(['profile'], {});
    //location.href = 'profile';
  }

  changePassword()
  {
    this.routerService.navigate(['profile/change-password'], {});
    //location.href = 'profile/change-password';
  }
  logout(): void {
    this.modal.confirm({
      nzTitle: this.translate.instant('auth.logout_confirm'),
      nzOkText: this.translate.instant('global.submit'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.submit(),
      nzCancelText: this.translate.instant('global.cancel'),
    });
  }
  submit(){
    Cache.deleteCacheUser();
    Cache.deleteCacheUrl();
    
    let data:any = {};
    data.type = "closeChangeCustomer";
    this.dataService.changeMessage(data);

    this.routerService.navigate(['auth/login'], {});
    //location.href = 'auth/login';
  }
}
