import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../Constant';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { cloneObject } from '~/app/shared/helper/object/clone.object';
import { AuthService } from '~/app/core/services/auth/auth.service';
import { Cache} from '../../../core/lib/cache';
import { CacheService } from "~/app/core/services/auth/cache.service";

@Injectable({
	providedIn: 'root'
})

export class MauInService {
	 
	//global config
	apiUrl = environment.backEndApiURL;
	reportURL = environment.reportURL;

	constructor(
		private http: HttpClient, 
		public router: Router, 
		public toast: Notification, 
		public authService: AuthService,
		public translate: TranslateService,
		private cache: CacheService) {	 
		// let token = this.authService.getWithExpiry("Token");
		// if (!token) {
		// this.authService.redirectToLoginPage();
		// }
	}

	GetList(page: number, limit: number, sort: any, filter: any){
		let res = {
			Page: page,
			PageSize: limit,
			Sort: sort,
			Filter: filter
		};
        let dataRequest = cloneObject(res)
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/GetGrid";
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

    GetOne(id: string){
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/GetById?id=" + id;
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}
	
    CreateOrUpdate(req: any){
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/SaveOrUpdate";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

	Delete(id: string){
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/Delete";
		let req = { ID: id};
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

    ExportFile(req: any){
		const url = this.reportURL.concat(Constant.REPORT) + "/ExportFile";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders(), responseType: 'blob' as 'json'});
	}
	
    ExportReport(req: any){
		const url = this.reportURL.concat(Constant.REPORT) + "/Export";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders(), responseType: 'blob' as 'json'});
	}

    ImportReport(file: any, req: any){
		let headers = new HttpHeaders({
			'enctype': 'multipart/form-data',
			'Authorization': "Bearer " + this.cache.getWithExpiry("Token")
		});
		let formData: FormData = new FormData(); 
	  
		formData.append('File', file);
		formData.append('ReportID', req.ID);
		const url = this.reportURL.concat(Constant.REPORT) + "/Import";
		return this.http.post(url, formData, {headers: headers});
	}
	
    LayDanhSachMau(){
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/LayDanhSachMau";
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}

	ImportDanhSachMau(req: any){
		const url = this.apiUrl.concat(Constant.MAU_IN) + "/ImportDanhSachMau";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

}
