import { Injectable } from "@angular/core";
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})

export class Notification  {

  constructor(
    public translate: TranslateService,
    private notificationService: NzNotificationService
  ) { }

  success(message: string){
    this.notificationService.success(this.translate.instant('global.notification'), message, { nzPlacement: 'bottomRight'});
  }
  
  error(message: string){
    this.notificationService.error(this.translate.instant('global.error'), message, { nzPlacement: 'bottomRight'});
  }

  warning(message: string){
    this.notificationService.warning(this.translate.instant('global.notification'), message, { nzPlacement: 'bottomRight'});
  }
}
