import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzNotificationModule
  ],
  exports: [
  ]
})
export class ReusedModule { }
