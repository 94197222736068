import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.OverviewModule) },
  { path: 'heThong', loadChildren: () => import('./heThong/heThong.module').then(m => m.HeThongModule) },
  { path: 'danhMuc', loadChildren: () => import('./danhMuc/danhMuc.module').then(m => m.DanhMucModule) },
  { path: 'khamBenh', loadChildren: () => import('./khamBenh/khamBenh.module').then(m => m.KhamBenhModule) },
  { path: 'kho', loadChildren: () => import('./kho/kho.module').then(m => m.KhoModule) },
  { path: 'hoaDon', loadChildren: () => import('./hoaDon/hoaDon.module').then(m => m.HoaDonModule) },
  { path: 'thongKe', loadChildren: () => import('./thongKe/thongKe.module').then(m => m.ThongKeModule) },
  { path: 'quanTri', loadChildren: () => import('./quanTri/quanTri.module').then(m => m.QuanTriModule) },
  { path: 'goi', loadChildren: () => import('./goi/goi.module').then(m => m.GoiModule) },
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: '**', redirectTo: 'error'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", onSameUrlNavigation: 'reload', })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
