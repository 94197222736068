import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingPageComponent } from './component/loading-page/loading-page.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ReportViewerModalComponent } from './component/report-viewer/report-viewer.component';
import { ReportDesignerModalComponent } from './component/report-designer/report-designer.component';
import { DxReportViewerModule, DxReportDesignerModule } from 'devexpress-reporting-angular';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [
    LoadingPageComponent,
    ReportViewerModalComponent,
    ReportDesignerModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    //NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
    NzSelectModule,
    NzTreeSelectModule,
    NzTagModule,
    NzTabsModule,
    NzDatePickerModule,
    NzDividerModule,
    NzTableModule,
    NzPaginationModule,
    NzAvatarModule,
    NzSpinModule,
    NzInputNumberModule,
    DxReportViewerModule,
    DxReportDesignerModule,
    NzMenuModule,
    NzUploadModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    //NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingPageComponent,
    ReportViewerModalComponent,
    ReportDesignerModalComponent
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    }
  }
}
