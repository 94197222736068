import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../Constant';
import { ApiReturn } from '../../../shared/models/global.model';
import { LoginModel, ChangePasswordDto, ForgotPassDto, ForgotPassComfirmDto } from '../../../shared/models/auth.model';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { Cache} from '../../../core/lib/cache';
import { CacheService } from "~/app/core/services/auth/cache.service";
import { cloneObject } from '~/app/shared/helper/object/clone.object';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  apiUrl = environment.backEndApiURL;
  hubUrl = environment.backEndApiURL + 'notifyhub';
  facebookAuth = environment.facebookAuth;
  googleAuth = environment.googleAuth;
  connectionHub: any;

  constructor( 
    private http: HttpClient, 
    public routerService: Router, 
    private route: ActivatedRoute, 
    public toast: Notification, 
    public translate: TranslateService,
    private cache: CacheService,) { 
  }

  userLogin(data: LoginModel){
    const url = this.apiUrl.concat(Constant.AUTH_USER_LOGIN);
    return this.http.post(url, data);
  }

  loginGoogle(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_USER_LOGIN_GOOGLE);
    return this.http.post(url, data);
  }

  loginFacebook(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_USER_LOGIN_FACEBOOK);
    return this.http.post(url, data);
  }

  changePassword(data: ChangePasswordDto){
    const url = this.apiUrl.concat(Constant.AUTH_USER_CHANGE_PASSWORD);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }

  account(){
		const url = this.apiUrl.concat(Constant.AUTH_USER_INFO) ;
		return this.http.get<any>(url, {headers: this.getAuthHeaders()});
	}

  changeAvatar(userId: string, file: File) {
    let headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': "Bearer " + this.cache.getWithExpiry("Token")
    });
    let formData: FormData = new FormData(); 

    formData.append('file', file);
    const url = this.apiUrl.concat(Constant.AUTH_USER_CHANGE_AVATA);
    return this.http.post(url, formData, {headers: headers});
  }

  Update(req: any){
		const url = this.apiUrl.concat(Constant.AUTH_USER_CHANGE_PROFILE);
        let dataRequest = cloneObject(req)
    //debugger
		return this.http.post<any>(url, dataRequest, {headers: this.getAuthHeaders()});
	}

  setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: (now.getTime() + ttl),
    };
    window.localStorage.setItem(key, JSON.stringify(item));
  }
  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  redirectToLoginPage(){
    this.routerService.navigate(['auth'], {});
  }

  checkValidToken(){
    const url = this.apiUrl.concat(Constant.AUTH_USER_CHECK);
    return this.http.get(url, {headers: this.getAuthHeaders()});
  }

  async checkValidTokenRouter(): Promise<any>{
    const url = this.apiUrl.concat(Constant.AUTH_USER_CHECK);
		return await this.http.get(url, {headers: this.getAuthHeaders()}).toPromise();
  }

  checkToken(){
    this.setURL();
    let token  = this.getWithExpiry("Token");
    if (!token) {
      this.redirectToLoginPage()
    } else {
      this.checkValidToken().subscribe((res: any) => {
        if (res.Code == 401){
          localStorage.removeItem("Token");
          this.redirectToLoginPage();
        }
        if (res.Data == false){
          this.redirectToLoginPage();
        }
      }, error => {
        if (error.status == 401 || error.status == 0 ){
          localStorage.removeItem("Token");
          this.redirectToLoginPage();
        }
      })
    }
  }
  
  setURL()
  {
    const currentUrl = this.routerService.url;
    let urlPath = null;
    if(currentUrl != null)
      urlPath = currentUrl;
    else
      urlPath = location.href;
      
    this.setWithExpiry("URL", urlPath, 3600 * 1000);
  }

  loadUrl(){
    var url = this.getWithExpiry("URL");
    if(url == undefined || url == null || url == "" )
    {
      this.routerService.navigate(['home']);
    }
    else
    {
      this.routerService.navigate([url]);
    }
  }
  
	checkRole(menuCode: string){
    const url = this.apiUrl.concat(Constant.AUTH_CHECK_ROLE) + "/?ChucNang=" + menuCode;
		return this.http.get(url, {headers: this.getAuthHeaders()});
	}
  
  async checkRoleRouter(menuCode: string): Promise<any> {
    const url = this.apiUrl.concat(Constant.AUTH_CHECK_ROLE_ROUTER) + "/?ChucNang=" + menuCode;
		return await this.http.get(url, {headers: this.getAuthHeaders()}).toPromise();
  }

  changeCustomer(data: LoginModel){
    const url = this.apiUrl.concat(Constant.AUTH_USER_CHANGE_CUSTOMER);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }

  infoCustomer(){
    const url = this.apiUrl.concat(Constant.AUTH_USER_INFO_CUSTOMER);
    return this.http.get(url, {headers: this.getAuthHeaders()});
  }
  
  getListCustomer(){
    const url = this.apiUrl.concat(Constant.AUTH_LIST_CUSTOMER);
    return this.http.get(url, {headers: this.getAuthHeaders()});
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
  
  loginLog()
  {
    let agent = window.navigator;
    var info:any = {};
    let ag = this.getAgent(agent);
    info.UngDung = ag?.browserName;
    info.PhienBan = ag?.fullVersion;
    info.HeDieuHanh = ag?.os;
    info.UserAgent = agent.userAgent;
    const url = this.apiUrl.concat(Constant.AUTH_USER_LOGIN_LOG);
    let dataRequest = cloneObject(info)
    this.http.post<any>(url, dataRequest, {headers: this.getAuthHeaders()}).subscribe(rs => {
    }, error => {
      console.log(error);
    });
  }

  getAgent(navigator: any)
  {
    var unknown:any = 'Unknown OS';
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset+6);
      if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset+7);
      if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
      browserName = nAgt.substring(nameOffset,verOffset);
      fullVersion = nAgt.substring(verOffset+1);
      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
      fullVersion  = ''+parseFloat(navigator.appVersion); 
      majorVersion = parseInt(navigator.appVersion,10);
    }
    // mobile version
    var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
  
    // system
    var os = unknown;
    var clientStrings = [{
      s: 'Windows 10',
      r: /(Windows 10.0|Windows NT 10.0)/
    }, {
      s: 'Windows 8.1',
      r: /(Windows 8.1|Windows NT 6.3)/
    }, {
      s: 'Windows 8',
      r: /(Windows 8|Windows NT 6.2)/
    }, {
      s: 'Windows 7',
      r: /(Windows 7|Windows NT 6.1)/
    }, {
      s: 'Windows Vista',
      r: /Windows NT 6.0/
    }, {
      s: 'Windows Server 2003',
      r: /Windows NT 5.2/
    }, {
      s: 'Windows XP',
      r: /(Windows NT 5.1|Windows XP)/
    }, {
      s: 'Windows 2000',
      r: /(Windows NT 5.0|Windows 2000)/
    }, {
      s: 'Windows ME',
      r: /(Win 9x 4.90|Windows ME)/
    }, {
      s: 'Windows 98',
      r: /(Windows 98|Win98)/
    }, {
      s: 'Windows 95',
      r: /(Windows 95|Win95|Windows_95)/
    }, {
      s: 'Windows NT 4.0',
      r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
    }, {
      s: 'Windows CE',
      r: /Windows CE/
    }, {
      s: 'Windows 3.11',
      r: /Win16/
    }, {
      s: 'Android',
      r: /Android/
    }, {
      s: 'Open BSD',
      r: /OpenBSD/
    }, {
      s: 'Sun OS',
      r: /SunOS/
    }, {
      s: 'Linux',
      r: /(Linux|X11)/
    }, {
      s: 'iOS',
      r: /(iPhone|iPad|iPod)/
    }, {
      s: 'Mac OS X',
      r: /Mac OS X/
    }, {
      s: 'Mac OS',
      r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
    }, {
      s: 'QNX',
      r: /QNX/
    }, {
      s: 'UNIX',
      r: /UNIX/
    }, {
      s: 'BeOS',
      r: /BeOS/
    }, {
      s: 'OS/2',
      r: /OS\/2/
    }, {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
  
    var osVersion = unknown;
  
    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }
  
    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;
  
      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;
  
      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
        break;
    }
    return {
      browserName: browserName,
      fullVersion: fullVersion,
      majorVersion: majorVersion,
      mobile: mobile,
      os: os,
      osVersion: osVersion,
    };
  }

  getOS(navigator: any)
  {
    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
    if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
    if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
    if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";
    return OSName;
  }

  forgotPass(data: ForgotPassDto){
    const url = this.apiUrl.concat(Constant.AUTH_FORGET_PASS);
    return this.http.post(url, data);
  }

  forgotConfirm(data: ForgotPassComfirmDto){
    const url = this.apiUrl.concat(Constant.AUTH_FORGET_CONFIRM);
    return this.http.post(url, data);
  }

  forgotCheck(data: ForgotPassComfirmDto){
    const url = this.apiUrl.concat(Constant.AUTH_FORGET_CHECK);
    return this.http.post(url, data);
  }

  getAuthHeaders(): any {
    return {
      Authorization: `Bearer ${this.cache.getWithExpiry("Token")}`
    };
  }

  listSSO(){
		const url = this.apiUrl.concat(Constant.AUTH_ListSSO) ;
		return this.http.get<any>(url, {headers: this.getAuthHeaders()});
	}

  addSSOGoogle(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_AddSSO_Google);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }

  addSSOFacebook(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_AddSSO_Facebook);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }

  removeSSO(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_RemoveSSO);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }
  
  updateStatuSSO(data: any){
    const url = this.apiUrl.concat(Constant.AUTH_UpdateStatuSSO);
    return this.http.post(url, data, {headers: this.getAuthHeaders()});
  }
}
