import { Component, OnInit, Output,EventEmitter,ViewChild, Input, ViewEncapsulation, SecurityContext, ElementRef } from "@angular/core";
import { AuthService } from "~/app/core/services/auth/auth.service";
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { en_US, NzI18nService, vi_VN } from "ng-zorro-antd/i18n";
import { DxReportDesignerComponent} from 'devexpress-reporting-angular'; 
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils"
import { AsyncExportApproach } from "devexpress-reporting/scopes/reporting-viewer-settings"
import { MauInService } from "~/app/core/services/quanTri/mauIn.service";
@Component({
  selector: "report-designer-modal",
  templateUrl: "./report-designer.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './report-designer.component.scss',
    "../../../../../node_modules/jquery-ui/themes/base/all.css",
    "../../../../../node_modules/devextreme/dist/css/dx.common.css",
    "../../../../../node_modules/devextreme/dist/css/dx.light.css",
    "../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    "../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css",
    "../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css",
    "../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css",
    "../../../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css"
  ]
})
export class ReportDesignerModalComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef<HTMLElement>;
  @Output() onReloadImport = new EventEmitter<any>();
  data:any = null;
  title = '';
  width = '800px';
  print = false;

  isVisible = false;
  isConfirmLoading = false;
  isSpinning = false;
  isPageLoading = false;
  
  getDesignerModelAction = "DXXRD/GetDesignerModel";
  reportUrl = null;
  hostUrl = environment.reportURL;
  request:any = {};
  fileList: File[] = [];

  constructor(
    public authService: AuthService,
    public toast: Notification,
    public translate: TranslateService,
    private modal: NzModalService,
    private i18n: NzI18nService,
    protected sanitizer: DomSanitizer,
		private http: HttpClient, 
    private mauInService: MauInService
  ) {
    ajaxSetup.ajaxSettings = { headers: this.authService.getAuthHeaders() };
    AsyncExportApproach(true);
  }

  ngOnInit() {
  }

  open(title: string, width: string, print: boolean, data: any): void {
    this.title = title;
    this.print = print;
    let req:any = { IDKhachHang: data.IDKhachHang, ReportName: data.ReportName, Type: 'Designer', ID: data.ID, Mau: data.Mau };
    this.reportUrl = JSON.stringify(req);
    this.width = width;
    this.data = data;
    this.isVisible = true;
    //console.log(req);
    this.request = req;
  }

  reOpen()
  {
    this.isVisible = true;
  }

  close(): void {
    this.isVisible = false;
  }

  beforeRender($event){
    //console.log('beforeRender', $event); 
  }

  customizeMenuActions($event){
    //console.log('customizeMenuActions', $event);
    var Exit = $event.args.GetById('dxrd-exit');
    if (Exit) Exit.visible = false;

    var NewReport =  $event.args.GetById('dxrd-newreport');
    if (NewReport) NewReport.visible = false;

    var NewReportViaWizard =  $event.args.GetById('dxrd-newreport-via-wizard');
    if (NewReportViaWizard) NewReportViaWizard.visible = false;

    var OpenReport = $event.args.GetById('dxrd-open-report');
    if (OpenReport) OpenReport.visible = false;

    var Localization = $event.args.GetById('dxrd-localization-editor');
    if (Localization) Localization.visible = false;

    var ReportWizard = $event.args.GetById('dxrd-run-wizard');
    if (ReportWizard) ReportWizard.visible = false;

    //var AddDataSource = $event.args.GetById('dxrd-add-sql-datasource');
    //if (AddDataSource) AddDataSource.visible = false;

    var AddMultiQuerySqlDataSource = $event.args.GetById('dxrd-add-multi-query-sql-datasource');
    if (AddMultiQuerySqlDataSource) AddMultiQuerySqlDataSource.visible = false;

    var AddSqlDataSource = $event.args.GetById('dxrd-add-sql-datasource');
    if (AddSqlDataSource) AddSqlDataSource.visible = false;

    var SaveAs = $event.args.GetById('dxrd-save-as');
    if (SaveAs) SaveAs.visible = false;
    let this_ = this;
    var actions = $event.args.Actions;
        //Add a new action
        actions.push({
            text: "Export",
            imageClassName: "dxrd-image-saveas",
            imageTemplateName: "dxrd-svg-menu-saveas",
            disabled: false,
            visible: true,
            hasSeparator: true,
            clickAction: function (report) {
              this_.mauInService.ExportReport(this_.request).subscribe(
                  (response) => { // download file
                    const dataType = 'repx';
                    const binaryData = [];
                    binaryData.push(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(
                      new Blob(binaryData, { type: dataType })
                    );
                    downloadLink.setAttribute('download', this_.request.ReportName + '.' + dataType);
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                }, error => {
                  console.log(error)
                  //this.toast.error(this.translate.instant('global.error_fail'));
                });
            },
            container: "menu"
        });
        actions.push({
            text: "Import",
            imageClassName: "dxrd-image-newreport",
            imageTemplateName: "dxrd-svg-menu-newreport",
            disabled: false,
            visible: true,
            hasSeparator: true,
            clickAction: function (report) {
              this_.onImport();
            },
            container: "menu"
        });
  }

  onImport(): void {
    this.modal.confirm({
      nzTitle: this.translate.instant('global.import_warning'),
      nzOkText: this.translate.instant('global.submit'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.submitImport(),
      nzCancelText: this.translate.instant('global.cancel'),
    });
  }
  submitImport(){
    this.fileUpload.nativeElement.click();
  }

  customizeElements($event){
    //console.log('customizeElements', $event);
  }

  fileUploadChange($event){
    if($event.target.files.length == 0)
    {
      this.isSpinning  = false;
      return;
    }
    let file = $event.target.files[0];
    let isType = file.name.toLowerCase().indexOf('.repx') >= 0 ? true : false;
    if (!isType) {
      this.toast.warning(this.translate.instant('type_report_invalid'));
      this.isSpinning  = false;
      return;
    }
    //1048576KB
    if (file.size > 1048576) {
      this.toast.warning(this.translate.instant('auth.avatar_size_invalid'));
      this.isSpinning  = false;
      return;
    }
    this.close();
    this.isPageLoading = true;
    this.mauInService.ImportReport(file, this.request).subscribe(
      (response) => { // download file
        if(response == true)
        {
          this.toast.success(this.translate.instant("global.import_success"));
        }
        setTimeout(() => {
          this.onReloadImport.emit(this.request);
          this.isPageLoading = false;
        }, 1000);
    }, error => {
      console.log(error)
      this.isPageLoading = false;
      //this.toast.error(this.translate.instant('global.error_fail'));
    })
  }
}
