import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../core/services/nav/nav.service';
import { ApiReturn } from '../models/global.model';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Input } from '@angular/core';
import { DataService } from '~/app/core/data.service';
import { Cache } from '../../core/lib/cache';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  LogoHeaderColor = 'dtzsoft1';
  TopBarColor = 'dtzsoft1';
  SideBarColor = 'dtzsoft1';
  BackgroundColor = 'dtzsoft1';
  BackgroundButton = 'dtzsoft1';

  openSetting = false;
  constructor(
    private router: Router, 
    public navServices: NavService,
    public toast: Notification,
    public translate: TranslateService,
    private dataService: DataService,
    ){
  }

  ngOnInit() {
    setTimeout(() => { 
      this.getColor();
      this.setColor();
    });
  }

  getColor()
  {
    let LogoHeaderColor = Cache.getCache("LogoHeaderColor");
    if(LogoHeaderColor) this.LogoHeaderColor = LogoHeaderColor;
    
    let TopBarColor = Cache.getCache("TopBarColor");
    if(TopBarColor) this.TopBarColor = TopBarColor;
    
    let SideBarColor = Cache.getCache("SideBarColor");
    if(SideBarColor) this.SideBarColor = SideBarColor;
    
    let BackgroundColor = Cache.getCache("BackgroundColor");
    if(BackgroundColor) this.BackgroundColor = BackgroundColor;
    
    let BackgroundButton = Cache.getCache("BackgroundButton");
    if(BackgroundButton) this.BackgroundButton = BackgroundButton;
  }

  setColor()
  {
    if(this.LogoHeaderColor) this.changeLogoHeaderColor(this.LogoHeaderColor);
    if(this.TopBarColor) this.changeTopBarColor(this.TopBarColor);
    if(this.SideBarColor) this.changeSideBarColor(this.SideBarColor);
    if(this.BackgroundColor) this.changeBackgroundColor(this.BackgroundColor);
    if(this.BackgroundButton) this.changeBackgroundButton(this.BackgroundButton);
  }

  settingOpen()
  {
    this.openSetting = !this.openSetting;
    var custom = document.getElementById('custom-template') as HTMLElement;
    if(this.openSetting)
      custom.classList.add("open");
    else
      custom.classList.remove("open");
  }

  close()
  {
    var custom = document.getElementById('custom-template') as HTMLElement;
    custom.classList.remove("open");
  }

  changeLogoHeaderColor(color: string)
  {
    try {
      Cache.cache("LogoHeaderColor", color);
      var list = document.getElementsByClassName('changeLogoHeaderColor');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLElement;
        element.classList.remove("selected");
      }
      
      var body = document.getElementsByClassName('logo-header')[0] as HTMLElement;
      body.removeAttribute('data-background-color');
      body.setAttribute('data-background-color', color);
      
      //var setting = document.getElementsByClassName('changeLogoHeaderColor_' + color)[0] as HTMLElement;
      //setting.classList.add('selected');
    } catch (error) {
      console.log(error);
    }
  }

  changeTopBarColor(color: string)
  {
    try {
      Cache.cache("TopBarColor", color);
      var list = document.getElementsByClassName('changeTopBarColor');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLElement;
        element.classList.remove("selected");
      }

      var body = document.getElementsByClassName('navbar-header')[0] as HTMLElement;
      body.removeAttribute('data-background-color');
      body.setAttribute('data-background-color', color);

      //var setting = document.getElementsByClassName('changeTopBarColor_' + color)[0] as HTMLElement;
      //setting.classList.add('selected');
    } catch (error) {
      console.log(error);
    }
  }

  changeSideBarColor(color: string)
  {
    try {
      Cache.cache("SideBarColor", color);
      var list = document.getElementsByClassName('changeSideBarColor');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLElement;
        element.classList.remove("selected");
      }
      var body = document.getElementsByClassName('sidebar')[0] as HTMLElement;
      body.removeAttribute('data-background-color');
      body.setAttribute('data-background-color', color);
      
      //var setting = document.getElementsByClassName('changeSideBarColor_' + color)[0] as HTMLElement;
      //setting.classList.add('selected');
    } catch (error) {
      console.log(error);
    }
  }
  
  changeBackgroundColor(color: string)
  {
    try {
      Cache.cache("BackgroundColor", color);
      var list = document.getElementsByClassName('changeBackgroundColor');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLElement;
        element.classList.remove("selected");
      }

      var body = document.getElementById('bdcontainer') as HTMLElement;
      body.removeAttribute('data-background-color');
      body.setAttribute('data-background-color', color);
      
      //var setting = document.getElementsByClassName('changeBackgroundColor_' + color)[0] as HTMLElement;
      //setting.classList.add('selected');
    } catch (error) {
      console.log(error);
    }
  }

  changeBackgroundButton(color: string)
  {
    try {
      Cache.cache("BackgroundButton", color);
      var list = document.getElementsByClassName('changeBackgroundButton');
      for (let i = 0; i < list.length; i++) {
        const element = list[i] as HTMLElement;
        element.classList.remove("selected");
      }

      var body = document.getElementById('main-panel') as HTMLElement;
      body.removeAttribute('data-background-color');
      body.setAttribute('data-background-color', color);
      
      var set = document.getElementById('custom-template') as HTMLElement;
      set.removeAttribute('data-background-color');
      set.setAttribute('data-background-color', color);
      
      var setting = document.getElementsByClassName('changeBackgroundButton_' + color)[0] as HTMLElement;
      setting.classList.add('selected');
    } catch (error) {
      console.log(error);
    }
  }

  setDefault()
  {
    this.LogoHeaderColor = 'dtzsoft1';
    this.TopBarColor = 'dtzsoft1';
    this.SideBarColor = 'dtzsoft1';
    this.BackgroundColor = 'dtzsoft1';
    this.BackgroundButton = 'dtzsoft1';
    
    this.setColor();
  }

  changeBackground(color: string)
  {
    this.LogoHeaderColor = color;
    this.TopBarColor = color;
    this.SideBarColor = color;
    this.SideBarColor = color;
    this.BackgroundColor = color;
    this.BackgroundButton = color;
    
    this.setColor();
  }

}
