export const environment = {
  production: false,
  //backEndApiURL: 'http://localhost:1125/',
  //reportURL: 'http://localhost:1126/',
  backEndApiURL: 'https://soft-api.dtzsoft.com/',
  reportURL: 'https://soft-report.dtzsoft.com/',
  facebookAuth: {
    appId: "565946392372896",
    //redirect: "http://localhost:4200/auth/facebook"
    redirect: "https://soft.dtzsoft.com/auth/facebook"
  },
  googleAuth: {
    clientId: "168909620471-rsmoplombpf3ji3f041c9jp21pgv901o.apps.googleusercontent.com",
    //redirect: "http://localhost:4200/auth/google"
    redirect: "https://soft.dtzsoft.com/auth/google"
  }
};

