<div class="sidebar sidebar-style-2">
  <perfect-scrollbar class="sidebar-wrapper scrollbar scrollbar-inner" fxFlex="auto" [scrollIndicators]="true">
    <div class="sidebar-content">
      <div class="user"><div class="avatar-sm float-left mr-2">
        <img src="{{avatar}}" class="avatar-img rounded-circle">
      </div>
      <div class="info">
        <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
          <span class="info-title">
              {{userFullname}}
              <span class="user-level">{{username}}</span>
              <span class="caret"></span>
            </span>
        </a>
        <div class="collapse in" id="collapseExample">
                <ul class="nav">
                  <li>
                    <a (click)="info()">
                      <span class="link-collapse"> 
                        <nz-tag [nzColor]="'#108ee9'">
                          <i class="fa fa-user"></i>
                        </nz-tag>
                          {{'auth.profile' | translate}}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="changePassword()">
                      <span class="link-collapse"> 
                        <nz-tag [nzColor]="'#108ee9'">
                          <i class="fa fa-lock"></i>
                        </nz-tag>
                        {{ "auth.change_password" | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="logout()">
                      <span class="link-collapse"> 
                        <nz-tag [nzColor]="'#f50'">
                          <i class="fa fa-sign-out-alt"></i>
                        </nz-tag>
                        {{ "auth.logout" | translate }}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      <ul class="nav nav-primary">
        <!--<li class="nav-item active"><a data-toggle="collapse" class="collapsed"><i class="fas fa-cogs"></i><p>MENU</p></a></li>-->
        <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
        <ng-template #menuTpl let-menus>
          <ng-container *ngFor="let menu of menus">
            <li class="nav-item" *ngIf="!menu.children" [ngClass]="menu.selected ? 'active' : ''">
              <a data-toggle="collapse" (click)="menuClick(menu)" >
                <i class="{{menu.icon}}"></i>
                <p>{{ menu.title }}</p>
              </a>
            </li>
            <li class="nav-item submenu" *ngIf="menu.children" [ngClass]="menu.selected ? 'active' : ''">
              <a data-toggle="collapse" [attr.data-target]="'#'+menu.id" (click)="menuClick(menu)">
                <i class="{{menu.icon}}"></i>
                <p>{{ menu.title }}</p>
                <span class="caret"></span>
              </a>
              <div class="collapse" id="{{menu.id}}"  [ngClass]="menu.selected ? 'show' : ''">
                <ul class="nav nav-collapse">
                  <!--<li *ngFor="let menuC of menu.children" (click)="menuClick(menuC)"><a><i nz-icon [nzType]="menuC.icon"></i><span class="sub-item">{{ menuC.title }}</span></a></li>-->
                  <li class="submenu" *ngFor="let menuC of menu.children" [ngClass]="menuC.selected ? 'active' : ''">
                    <a data-toggle="collapse" [attr.data-target]="'#'+menuC.id" (click)="menuClick(menuC)" >
                      <i class="{{menuC.icon}}"></i>
                      <span class="sub-item">{{ menuC.title }}</span>
                      <span class="caret" *ngIf="menuC.children"></span>
                    </a>
                    <!-- <div class="collapse" *ngIf="menuC.children" id="{{menuC.id}}">
                      <ul class="nav nav-collapse subnav">
                        <li *ngFor="let menuD of menuC.children" (click)="menuClick(menuD)">
                          <a>
                            <i class="{{menuD.icon}}"></i>
                            <span class="sub-item">{{ menuD.title }}</span>
                          </a>
                        </li>
                      </ul>
                    </div> -->
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>
        </ng-template>
      </ul>
    </div>
  </perfect-scrollbar>
</div>
<!--
<perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true"></perfect-scrollbar>
-->