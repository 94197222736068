import { Component, OnInit, Output,EventEmitter,ViewChild, Input, ViewEncapsulation, SecurityContext } from "@angular/core";
import { AuthService } from "~/app/core/services/auth/auth.service";
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { en_US, NzI18nService, vi_VN } from "ng-zorro-antd/i18n";
import { DxReportViewerComponent} from 'devexpress-reporting-angular'; 
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { MauInService } from "~/app/core/services/quanTri/mauIn.service";
import { environment } from '../../../../environments/environment';
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils"
import { AsyncExportApproach } from "devexpress-reporting/scopes/reporting-viewer-settings"

@Component({
  selector: "report-viewer-modal",
  templateUrl: "./report-viewer.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './report-viewer.component.scss',
    "../../../../../node_modules/jquery-ui/themes/base/all.css",
    "../../../../../node_modules/devextreme/dist/css/dx.common.css",
    "../../../../../node_modules/devextreme/dist/css/dx.light.css",
    "../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    "../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css",
    "../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css",
    "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css",
  ]
})
export class ReportViewerModalComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<any>();
  data:any = null;
  title = '';
  width = '800px';

  isVisible = false;
  isConfirmLoading = false;
  isSpinning = false;
  isPageLoading = false;
  
  reportUrl: string = null;
  invokeAction: string = '/DXXRDV';
  hostUrl = environment.reportURL

  constructor(
    public authService: AuthService,
    public toast: Notification,
    public translate: TranslateService,
    private modal: NzModalService,
    private i18n: NzI18nService,
    protected sanitizer: DomSanitizer,
		private http: HttpClient, 
    private mauInService: MauInService,
  ) {
    ajaxSetup.ajaxSettings = { headers: this.authService.getAuthHeaders() };
    AsyncExportApproach(true);
  }

  ngOnInit() {
  }

  open(title: string, width: string, showControl: boolean, data: any): void {
    this.title = title;
    let req:any = { IDKhachHang: data.IDKhachHang, ReportName: data.ReportName, Value: data.Value, Type: 'Viewer' };
    this.reportUrl = JSON.stringify(req);
    this.width = width;
    this.data = data;
    console.log(req);
    if(showControl == false ){
      req.TypeExport = 'pdf';
      this.printPdf(req);
    }
    else {
      this.isVisible = true;
    }
  }

  close(): void {
    this.isVisible = false;
  }

  beforeRender($event){
    //console.log('beforeRender', $event); 
    $event.args.reportPreview.zoom(1);
    this.onSubmit.emit(true);
  }

  customizeMenuActions($event){
    //console.log('customizeMenuActions', $event);
  }

  customizeElements($event){
    //console.log('customizeElements', $event);
  }

  printPdf(res: any) {
    //this.isPageLoading = true;
		this.mauInService.ExportFile(res).subscribe(
      (response) => { // download file
        var blob = new Blob([response], {type: 'application/pdf'});
        const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
          //this.isPageLoading = false;
          this.onSubmit.emit(true);
    }, error => {
      //this.isPageLoading = false;
      console.log(error)
      this.onSubmit.emit(false);
      //this.toast.error(this.translate.instant('global.error_fail'));
    })
  }
}
