<div class="wrapper main-container sidebar_minimize" id="main-container">
  <app-navbar *ngIf="showNavbar"></app-navbar>
  <app-sidebar *ngIf="showSidebar"></app-sidebar>
  <div class="main-panel" id="main-panel">
    <div class="content">
      <div contentAnimate *ngIf="!isLoading" class="h-100">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer *ngIf="showSidebar"></app-footer>
  </div>
</div>
<app-customer-change-modal></app-customer-change-modal>
<app-setting *ngIf="showSeting"></app-setting>
<app-loading *ngIf="showLoading"></app-loading>
