<div class="main-header">
  <!-- Logo Header -->
  <div class="logo-header">
    <a class="logo">
      <img src="assets/images/logo/logo.png" class="navbar-brand" />
      {{ "project.subname" | translate }}
    </a>
    <button class="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" (click)="toggleSidebar()">
        <i class="icon-menu"></i>
      </span>
    </button>
    <button class="topbar-toggler more" (click)="toggleNarbar()">
      <i class="icon-options-vertical"></i>
    </button>
    <!-- <div class="nav-toggle">
      <button class="btn btn-toggle toggle-sidebar" (click)="toggleSidebar()">
        <i class="icon-menu"></i>
      </button>
    </div> -->
  </div>
  <!-- End Logo Header  class="dropdown-menu notif-box animated fadeIn"-->
  <!-- Navbar Header -->
  <nav class="navbar navbar-header navbar-expand-lg"> 
    <button class="btn btn-toggle toggle-sidebar" (click)="toggleSidebar()">
      <i class="icon-menu"></i>
    </button>
    <div class="container-fluid">
      <div class="collapse" id="search-nav">
        <div class="nav-name">
          <!-- {{ "project.name" | translate }} -->
          {{custName}}
        </div>
        <div class="nav-des" *ngIf="custName" (click)="changeCustomer()">
          <!-- <span class="nav-des-h" style="text-transform: uppercase;background-color: rgb(135, 208, 104);padding: 5px;">
            {{custName}}
          </span> -->
          <span class="nav-des-h" style="background-color: rgb(255, 85, 0);padding: 5px;" *ngIf="khoaPhong">
            {{khoaPhong}}
          </span>
          <span class="nav-des-h" style="background-color: rgb(16, 142, 233);padding: 5px;" *ngIf="caLamViec">
            {{caLamViec}}
          </span>
        </div>
      </div>
      <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
        <!-- <li class="nav-item hidden-caret"><div class="nav-language"><a id="languageToggle" (click)="changeLanguage()"></a></div></li> -->
        <li class="nav-item submenu" *ngIf="!isMobile">
          <a class="nav-link quick-sidebar-toggler" (click)="fullScreen()">
            <i class="fa fa-expand" aria-hidden="true"></i>
          </a>
        </li>
        <li class="nav-item dropdown hidden-caret submenu">
          <a class="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-bell"></i>
            <span class="notification">{{ notifiCount }}</span>
          </a>
          <ul class="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
            <li>
              <div class="dropdown-title">Bạn có {{ notifiCount }} thông báo!</div>
            </li>
            <li>
              <div class="scroll-wrapper notif-scroll scrollbar-outer" style="position: relative;">
                <div class="notif-scroll scrollbar-outer scroll-content" style="height: auto; margin-bottom: 0px; margin-right: 0px; max-height: 256px;">
                <div class="notif-center">
                  <!-- <a href="#">
                    <div class="notif-icon notif-primary"> <i class="fa fa-user-plus"></i> </div>
                    <div class="notif-content">
                      <span class="block">
                        New user registered
                      </span>
                      <span class="time">5 minutes ago</span> 
                    </div>
                  </a> -->
                 
                </div>
              </div>
              </div>
            </li>
            <li>
              <a class="see-all" (click)="messager()">Xem tất cả thông báo<i class="fa fa-angle-right"></i> </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item dropdown hidden-caret submenu">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fas fa-layer-group"></i>
          </a>
          <div class="dropdown-menu quick-actions quick-actions-info animated fadeIn">
            <div class="scroll-wrapper quick-actions-scroll scrollbar-outer" style="position: relative;">
              <div class="quick-actions-scroll scrollbar-outer scroll-content" style="height: auto; margin-bottom: 0px; margin-right: 0px; max-height: 246.939px;">
              <div class="quick-actions-items">
                <div class="row m-0">
                  <a class="col-6 col-md-6 p-0" href="#">
                    <div class="quick-actions-item">
                      <div class="avatar-item bg-danger rounded-circle">
                        <i class="far fa-calendar-alt"></i>
                      </div>
                      <span class="text">Hướng dẫn sử dụng</span>
                    </div>
                  </a>
                  <a class="col-6 col-md-6 p-0" href="#">
                    <div class="quick-actions-item">
                      <div class="avatar-item bg-warning rounded-circle">
                        <i class="fas fa-map"></i>
                      </div>
                      <span class="text">Hỗ trợ từ xa</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
               </div>
          </div>
        </li> -->
        <li class="nav-item dropdown hidden-caret">
          <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
            <div class="avatar-sm">
              <img src="{{ avatar }}" class="avatar-img rounded-circle" />
              <span class="name-sm">{{ userFullname }}</span>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-user animated fadeIn">
            <div class="dropdown-user-scroll scrollbar-outer">
              <li>
                <div class="user-box">
                  <div class="avatar-lg">
                    <img src="{{ avatar }}" class="avatar-img rounded" />
                  </div>
                  <div class="u-text">
                    <h4>{{ userFullname }}</h4>
                    <p class="text-muted">{{ username }}</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="info()">
                  <nz-tag [nzColor]="'#108ee9'">
                    <i class="fa fa-user"></i>
                  </nz-tag>
                  {{ "auth.profile" | translate }}
                </a>
                <a class="dropdown-item" (click)="changePassword()">
                  <nz-tag [nzColor]="'#108ee9'">
                    <i class="fa fa-lock"></i>
                  </nz-tag>
                  {{ "auth.change_password" | translate }}
                </a>
                <a class="dropdown-item" (click)="settingOpen()">
                  <nz-tag [nzColor]="'#108ee9'">
                    <i class="fa fa-layer-group"></i>
                  </nz-tag> Thay đổi giao diện
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="changeCustomer()">
                  <nz-tag [nzColor]="'#87d068'">
                    <i class="fa fa-users-cog"></i>
                  </nz-tag> Thiết lập phòng khám
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">
                  <nz-tag [nzColor]="'#f50'">
                    <i class="fa fa-sign-out-alt"></i>
                    {{ "auth.logout" | translate }}
                  </nz-tag>
                </a>
              </li>
            </div>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</div>