
import { environment } from '../../environments/environment';

export const Constant = {
    //auth
    AUTH_USER_LOGIN: 'auth/Token',
    AUTH_USER_CHECK: 'auth/CheckToken',
    AUTH_USER_CHANGE_PASSWORD: 'auth/ChangePassword',
    AUTH_USER_INFO: 'auth/Account',
    AUTH_USER_CHANGE_AVATA: 'auth/ChangeAvatar',
    AUTH_USER_CHANGE_PROFILE: 'auth/ChangeProfile',
    AUTH_USER_CHANGE_CUSTOMER: 'auth/ChangeCustomer',
    AUTH_USER_INFO_CUSTOMER: 'auth/InfoCustomer',
    AUTH_USER_LOGIN_LOG: 'api/heThong/Log/LoginLog',
    AUTH_FORGET_PASS: 'auth/ForgotPassword',
    AUTH_FORGET_CONFIRM: 'auth/ForgotPasswordConfirm',
    AUTH_FORGET_CHECK: 'auth/ForgotPasswordCheck',
    AUTH_LIST_CUSTOMER: 'auth/GetListCustomer',
    AUTH_CHECK_ROLE: 'auth/CheckRole',
    AUTH_CHECK_ROLE_ROUTER: 'auth/CheckRoleRouter',
    AUTH_CHECK_ROLE_FUNCTION: 'auth/CheckRoleFunction',
    AUTH_GET_MENU: 'auth/GetMenu',
    AUTH_USER_LOGIN_GOOGLE: 'auth/Google',
    AUTH_USER_LOGIN_FACEBOOK: 'auth/Facebook',
    AUTH_ListSSO: 'auth/ListSSO',
    AUTH_AddSSO_Google: 'auth/AddSSOGoogle',
    AUTH_AddSSO_Facebook: 'auth/AddSSOFacebook',
    AUTH_RemoveSSO: 'auth/RemoveSSO',
    AUTH_UpdateStatuSSO: 'auth/UpdateStatuSSO',

    CHUC_NANG: 'api/heThong/ChucNang',
    KHACH_HANG: 'api/danhMuc/KhachHang',
    PHAN_QUYEN: 'api/heThong/PhanQuyen',
    NGUOI_DUNG: 'api/heThong/NguoiDung',
    TINH_THANH: 'api/danhMuc/TinhThanh',
    QUAN_HUYEN: 'api/danhMuc/QuanHuyen',
    PHUONG_XA: 'api/danhMuc/PhuongXa',
    DON_VI_TINH: 'api/danhMuc/DonViTinh',
    CA_LAM_VIEC: 'api/danhMuc/CaLamViec',
    CHAN_DOAN: 'api/danhMuc/ChanDoan',
    VIET_TAT: 'api/danhMuc/VietTat',
    DICH_VU: 'api/danhMuc/DichVu',
    DOI_TUONG: 'api/danhMuc/DoiTuong',
    KHOA_PHONG: 'api/danhMuc/KhoaPhong',
    NHOM_DICH_VU: 'api/danhMuc/NhomDichVu',
    PHAN_LOAI: 'api/danhMuc/PhanLoai',
    QUY_CACH: 'api/danhMuc/QuyCach',
    THUOC: 'api/danhMuc/Thuoc',
    MAU_IN: 'api/danhMuc/MauIn',
    MAU_IN_MAU: 'api/heThong/MauIn',
    HINH_THUC_THANH_TOAN: 'api/danhMuc/HinhThucThanhToan',
    DANH_SACH_BENH_NHAN: 'api/phongKham/CapThe',
    TIEP_NHAN_BENH_NHAN: 'api/phongKham/TiepNhan',
    CHI_DINH_DICH_VU: 'api/phongKham/DichVu',
    LAP_TOA_THUOC: 'api/phongKham/ToaThuoc',
    REPORT: 'api/report',
    PHIEU_THU: 'api/phongKham/PhieuThu',
    DASHBOARD: 'api/thongKe/Dashboard',
    NHOM_THAM_SO: 'api/heThong/NhomThamSo',
    THAM_SO: 'api/danhMuc/ThamSo',
    THAM_SO_MAU: 'api/heThong/ThamSo',
    CAU_HINH_MAIL: 'api/heThong/CauHinhMail',
    MAIL_MAU: 'api/heThong/MailMau',
    LOG_HE_THONG: 'api/heThong/Log',
    DV_DANG_KY: 'api/dichVu/DangKy',
    NHAP_THUOC: 'api/kho/NhapThuoc',
    HOAN_THUOC: 'api/kho/HoanThuoc',
    KIEM_KHO: 'api/kho/KiemKho',
    BAN_THUOC: 'api/hoaDon/BanThuoc',
    THONG_KE_DICH_VU: 'api/thongKe/DichVu',
    THONG_KE_KHAM_BENH: 'api/thongKe/KhamBenh',
    THONG_KE_DICH_VU_CT: 'api/thongKe/DichVuCT',
    THONG_KE_PHIEU_THU: 'api/thongKe/PhieuThu',
    THONG_KE_TIEN_THUOC: 'api/thongKe/TienThuoc',
    THONG_KE_TON_KHO: 'api/thongKe/TonKho',
    THONG_KE_NHAP_XUAT: 'api/thongKe/NhapXuat',
    DV_CHUYEN_MUC: 'api/dichVu/ChuyenMuc',
    DV_GOI: 'api/dichVu/Goi',
    DV_LOAI_HINH: 'api/dichVu/LoaiHinh',
    TOA_THUOC_MAU: 'api/danhMuc/ToaThuocMau',
    DICH_VU_MAU: 'api/danhMuc/DichVuMau',
    DV_HINH_THUC_THANH_TOAN: 'api/dichVu/HinhThucThanhToan',
    TIN_NHAN: 'api/quanTri/TinNhan',
    TU_CHOI_MAU: 'api/dichvu/TuChoiMau',
}

