<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="close()" [nzKeyboard]="true" [nzMaskClosable]="false" [nzWidth]="width">
  <ng-template #modalTitle> <i class="fa fa-print" style="padding-right: 5px;"></i> {{title}}</ng-template>
  <ng-template #modalContent>
    <nz-spin [nzSpinning]="isSpinning" nzSimple style="text-align: center;"></nz-spin>
    <dx-report-viewer [reportUrl]="reportUrl" #reportViewer>
      <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
      <dxrv-export-settings [showPrintNotificationDialog]="false"></dxrv-export-settings>
      <dxrv-callbacks 
      (BeforeRender)="beforeRender($event)" 
      (CustomizeMenuActions)="customizeMenuActions($event)"
      (CustomizeElements)="customizeElements($event)">
    </dxrv-callbacks>
    </dx-report-viewer>    
  </ng-template>
</nz-modal>
<loading-page [isPageLoading]="isPageLoading"></loading-page>