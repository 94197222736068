import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../Constant';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { cloneObject } from '~/app/shared/helper/object/clone.object';
import { AuthService } from '~/app/core/services/auth/auth.service';

@Injectable({
	providedIn: 'root'
})

export class KhoaPhongService {
	 
	//global config
	apiUrl = environment.backEndApiURL;

	constructor(
		private http: HttpClient, 
		public router: Router, 
		public toast: Notification, 
		public authService: AuthService,
		public translate: TranslateService) {	 
		// let token = this.authService.getWithExpiry("Token");
		// if (!token) {
		// this.authService.redirectToLoginPage();
		// }
	}
	
    Select(){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/GetAllForCombobox";
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}

    SelectKH(IDKhachHang: any){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/GetAllKHForCombobox?IDKhachHang=" + IDKhachHang;
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}

	GetList(page: number, limit: number, sort: any, filter: any){
		let res = {
			Page: page,
			PageSize: limit,
			Sort: sort,
			Filter: filter
		};
        let dataRequest = cloneObject(res)
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/GetGrid";
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

    GetOne(id: string){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/GetById?id=" + id;
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}
	
    CreateOrUpdate(req: any){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/SaveOrUpdate";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

	Delete(id: string){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/Delete";
		let req = { ID: id};
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

	Deletes(data: string[]){
		let req =[];
		if(data)
		{
			data.forEach(item => {
				req.push({ID: item});
			});
		}
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/Deletes";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}
	
    Import(req: any){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/Import";
        let dataRequest = cloneObject(req)
		return this.http.post<any>(url, dataRequest, {headers: this.authService.getAuthHeaders()});
	}

    Export(){
		const url = this.apiUrl.concat(Constant.KHOA_PHONG) + "/Export";
		return this.http.get<any>(url, {headers: this.authService.getAuthHeaders()});
	}
}
