<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" nzCentered  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="close()" [nzKeyboard]="false" [nzMaskClosable]="false" [nzClosable]="false">
  <ng-template #modalTitle>Thiết lập phòng khám</ng-template>
  <ng-template #modalContent>
    <nz-spin [nzSpinning]="isSpinning" nzSimple style="text-align: center;"></nz-spin>
    <form *ngIf="!isSpinning" nz-form [formGroup]="validateForm">
      <nz-form-item>
        <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired nzFor="ID" class="float-left">{{'setting.name' | translate}} <span class="required">(*)</span></nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="{{'setting.name_require' | translate}}">
          <nz-select formControlName="ID" [(ngModel)]="ID" nzPlaceHolder="{{'setting.name_placeholder' | translate}}" (ngModelChange)="onKhachHangChange($event)" style="color: red">
            <nz-option *ngFor="let item of dataKhachHang" [nzLabel]="item.Ten" [nzValue]="item.ID"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="IDKhoaPhong" class="float-left">{{'setting.phongkham' | translate}}</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="{{'setting.phongkham_require' | translate}}">
          <nz-select formControlName="IDKhoaPhong" [(ngModel)]="IDKhoaPhong" nzPlaceHolder="{{'setting.phongkham_placeholder' | translate}}" style="color: blue">
            <nz-option *ngFor="let item of dataKhoaPhong" [nzLabel]="item.Ten" [nzValue]="item.ID"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="IDCaLamViec" class="float-left">{{'setting.calamviec' | translate}}</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="{{'setting.calamviec_require' | translate}}">
          <nz-select formControlName="IDCaLamViec" [(ngModel)]="IDCaLamViec" nzPlaceHolder="{{'setting.calamviec_placeholder' | translate}}" style="color: orange">
            <nz-option *ngFor="let item of dataCaLamViec" [nzLabel]="item.Ten" [nzValue]="item.ID"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button *ngIf="!isSpinning" nz-button nzType="primary" (click)="submitForm()" [nzLoading]="isConfirmLoading">
      <i class="fa fa-save"></i>{{ 'global.edit' | translate}}
    </button>
    <button *ngIf="!hideBtnClose" nz-button nzType="default" (click)="close()">
      <i class="fa fa-close"></i>{{'global.cancel' | translate}}
    </button>
  </ng-template>
</nz-modal>