import { Component, OnInit } from '@angular/core';
import { AuthService } from '~/app/core/services/auth/auth.service';
import { DataService } from '~/app/core/data.service';
import {Cache} from '~/app/core/lib/cache';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private dataService: DataService,
  ) { }

  userFullname = Cache.getCache("UserName");
  
  ngOnInit() {
    this.dataService.currentMessage.subscribe(message => this.onReceiveCall(message));

    this.loadHoTen();
  }

  loadHoTen()
  {
    let hoTen = Cache.getCache("HoTen");
    if(hoTen)
      this.userFullname = hoTen;
  }

  onReceiveCall(data: any) {
    if(data.type == "changeProfile")
    {
      this.loadHoTen();
    }
  }

}
