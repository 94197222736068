<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" nzCentered [nzFooter]="null" (nzOnCancel)="close()" [nzKeyboard]="false" [nzMaskClosable]="false" [nzWidth]="width">
  <ng-template #modalTitle> <i class="fa fa-print" style="padding-right: 5px;"></i> {{title}}</ng-template>
  <ng-template #modalContent>
    <nz-spin [nzSpinning]="isSpinning" nzSimple style="text-align: center;"></nz-spin>
    <dx-report-designer [reportUrl]="reportUrl" height="700px">
      <dxrd-request-options [getDesignerModelAction]="getDesignerModelAction" [host]="hostUrl"></dxrd-request-options>
      <dxrd-callbacks 
        (BeforeRender)="beforeRender($event)" 
        (CustomizeMenuActions)="customizeMenuActions($event)"
        (CustomizeElements)="customizeElements($event)">
      </dxrd-callbacks>
    </dx-report-designer>
    <input #fileUpload type="file" style="display:none" (change)="fileUploadChange($event)" />
  </ng-template>
</nz-modal>
<loading-page [isPageLoading]="isPageLoading"></loading-page>