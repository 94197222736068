import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Notification } from "~/app/reused/notification/notification.component";
import { TranslateService } from '@ngx-translate/core';
import { KhachHangService } from '~/app/core/services/heThong/khachHang.service';
import { AuthService } from '~/app/core/services/auth/auth.service';
import {Cache} from '~/app/core/lib/cache';
import { ApiReturn } from '../../shared/models/global.model';
import { LoginModel, LoginReponseModel } from '../../shared/models/auth.model';
import { DataService } from '~/app/core/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { KhoaPhongService } from '~/app/core/services/danhMuc/khoaPhong.service';
import { CaLamViecService } from '~/app/core/services/danhMuc/caLamViec.service';

@Component({
  selector: 'app-customer-change-modal',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerChangeComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<any>();

  isVisible = false;
  isConfirmLoading = false;
  isSpinning = false;
  dataKhachHang = [];
  dataKhoaPhong = [];
  dataCaLamViec = [];
  validateForm!: FormGroup;
  ID = null;
  IDKhoaPhong = null;
  IDCaLamViec = null;
  hideBtnClose = false;

  constructor(
    public khachHangService: KhachHangService, 
    public toast: Notification,
    public translate: TranslateService,
    public authService: AuthService, 
    private dataService: DataService,
    private fb: FormBuilder,
    public routerService: Router, 
    private route: ActivatedRoute, 
    private khoaPhongService: KhoaPhongService,
    private caLamViecService: CaLamViecService
    ) { 
    }

  ngOnInit() {
    this.validateForm = this.fb.group({
      ID: [null, [Validators.required]],
      IDKhoaPhong: [null],
      IDCaLamViec: [null],
    });
  }

  getListKhachHang()
  {
    this.ID = Cache.getCache("IDKhachHang");
    this.IDCaLamViec = Cache.getCache("IDCaLamViec");
    this.IDKhoaPhong = Cache.getCache("IDKhoaPhong");
    this.authService.getListCustomer()
    .subscribe((res: any) => {
      this.dataKhachHang = [];
      if (res.Code == 1) {
        this.dataKhachHang = res.Data;
      }
      else {
        this.toast.error(this.translate.instant('global.fail'));
      }
    }, error => {
      console.log(error)
      //this.toast.error(this.translate.instant('global.error_fail'));
    });
  }

  onKhachHangChange($event)
  {
    this.getListKhoaPhong($event);
    this.getListCaLamViec($event);
  }

  getListKhoaPhong(IDKhachHang: any)
  {
    if(IDKhachHang)
    {
      this.khoaPhongService.SelectKH(IDKhachHang)
      .subscribe((res: any) => {
        this.dataKhoaPhong = [];
        if (res.Code == 1) {
          if (res.Data.length > 0)
          {
            this.dataKhoaPhong = res.Data;
            this.IDKhoaPhong = Cache.getCache("IDKhoaPhong");
          }
          else
          {
            this.IDKhoaPhong = null;
          }
        }
        else {
          this.toast.error(this.translate.instant('global.fail'));
        }
      }, error => {
        console.log(error)
        //this.toast.error(this.translate.instant('global.error_fail'));
      });
    }
  }

  getListCaLamViec(IDKhachHang: any)
  {
    if(IDKhachHang)
    {
      this.caLamViecService.SelectKH(IDKhachHang)
      .subscribe((res: any) => {
        this.dataCaLamViec = [];
        if (res.Code == 1) {
          if (res.Data.length > 0)
          {
            this.dataCaLamViec = res.Data;
            this.IDCaLamViec = Cache.getCache("IDCaLamViec");
          }
          else
          {
            this.IDCaLamViec = null;
          }
        }
        else {
          this.toast.error(this.translate.instant('global.fail'));
        }
      }, error => {
        console.log(error)
        //this.toast.error(this.translate.instant('global.error_fail'));
      });
    }
  }

  open(hideBtnClose: boolean): void {
    this.hideBtnClose = hideBtnClose;
    this.isVisible = true;
    this.getListKhachHang();
  }
  
  close(): void {
    this.isVisible = false;
  }

  submitForm(): void {
     //Kiểm tra validate
     for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if(this.validateForm.invalid) return;

    let request:any= this.validateForm.value;

    this.authService.changeCustomer(request).subscribe((res: any) => {
      if(res.Code == 1){
        let data = res.Data as LoginReponseModel;
        let info = this.authService.parseJwt(data.access_token); 
        if(info != null)
        {
          this.toast.success('Thiết lập phòng khám thành công!');
          Cache.cache("IDKhachHang", info.IDKhachHang);
          let khoaPhong = this.dataKhoaPhong.find(o => o.ID == this.IDKhoaPhong);
          if(khoaPhong)
          {
            Cache.cache("IDKhoaPhong", this.IDKhoaPhong);
            Cache.cache("KhoaPhong", khoaPhong.Ten);
          }
          else
          {
            Cache.remove("IDKhoaPhong");
            Cache.remove("KhoaPhong");
          }
          //Cache.cache("IDCaLamViec", this.IDCaLamViec);
          let caLamViec = this.dataCaLamViec.find(o => o.ID == this.IDCaLamViec);
          if(caLamViec)
          {
            Cache.cache("IDCaLamViec", this.IDCaLamViec);
            Cache.cache("CaLamViec", caLamViec.Ten);
          }
          else
          {
            Cache.remove("IDCaLamViec");
            Cache.remove("CaLamViec");
          }
          this.authService.setWithExpiry("Token", data.access_token, info.exp);   
          
          this.close();
          window.location.reload();
        }
      } 
      else {
        this.toast.warning('Có lỗi trong quá trình thay đổi! Bạn vui lòng thử lại sau!');
      }
    }, error => {
      //this.isShowLoading = false;
      this.toast.error(this.translate.instant('error_system'));
    })
  }
}
