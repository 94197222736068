import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from "@angular/common";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContentAnimateDirective } from "./shared/directives/content-animate.directive";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ReusedModule } from "./reused/reused.module";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { vi_VN, en_US } from 'ng-zorro-antd/i18n';
//import en from '@angular/common/locales/en';
//import vi from '@angular/common/locales/vi';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CustomerChangeComponent } from "./shared/customer/customer.component";
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SettingComponent } from "./shared/setting/setting.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { SharedModule } from "./shared/shared.module";
import { LOCALE_ID } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { vi } from 'date-fns/locale';
import { LoadingComponent } from "./shared/loading/loading.component";

//registerLocaleData(en);
//registerLocaleData(vi);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

export function HttpLoaderFactory(http: HttpClient) {  
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ContentAnimateDirective,
    CustomerChangeComponent,
    SettingComponent,
    LoadingComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReusedModule,
    NzMenuModule,
    NzToolTipModule,
    NzIconModule,
    NzSpinModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
    NzSelectModule,
    NzTreeSelectModule,
    NzTagModule,
    NzTabsModule,
    NzDatePickerModule,
    NzDividerModule,
    NzTableModule,
    NzPaginationModule,
    NzAvatarModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: NZ_I18N,
      useValue: vi_VN,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          case 'vi':
            return vi_VN;
          default:
            return vi_VN;
        }
      },
      deps: [LOCALE_ID]
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private i18n: NzI18nService) { 
    this.i18n.setDateLocale(vi);
  }
}