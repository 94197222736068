<!-- Custom template | don't include it in your project! -->
<div class="custom-template" id="custom-template">
  <div class="title">Thay đổi giao diện</div>
  <div class="custom-content">
    <div class="switcher">
      <div class="switch-block">
        <div class="btnSwitch">
          <button type="button" class="changeBackgroundButton selected changeBackgroundButton_dtzsoft1" data-color="dtzsoft1" (click)="changeBackground('dtzsoft1')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft2" data-color="dtzsoft2" (click)="changeBackground('dtzsoft2')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft3" data-color="dtzsoft3" (click)="changeBackground('dtzsoft3')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft4" data-color="dtzsoft4" (click)="changeBackground('dtzsoft4')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft5" data-color="dtzsoft5" (click)="changeBackground('dtzsoft5')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft6" data-color="dtzsoft6" (click)="changeBackground('dtzsoft6')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft7" data-color="dtzsoft7" (click)="changeBackground('dtzsoft7')"></button>
          <button type="button" class="changeBackgroundButton changeBackgroundButton_dtzsoft8" data-color="dtzsoft8" (click)="changeBackground('dtzsoft8')"></button>
        </div>
      </div>
     
      <div class="switch-block">
        <button nz-button nzType="dashed" (click)="setDefault()">
          <i class="fa fa-sync"></i> Cài đặt mặc định </button>
        <button nz-button nzType="dashed" (click)="close()">
          <i class="fa fa-close"></i> Thoát </button>
      </div>
    </div>
  </div>
  <!-- <div class="custom-toggle" (click)="settingOpen()"><i class="flaticon-settings"></i></div> -->
</div>
<!-- End Custom template -->